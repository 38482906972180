exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-adatvedelmi-szabalyzat-index-jsx": () => import("./../../../src/data/pages/adatvedelmi-szabalyzat/index.jsx" /* webpackChunkName: "component---src-data-pages-adatvedelmi-szabalyzat-index-jsx" */),
  "component---src-data-pages-cookie-szabalyzat-index-jsx": () => import("./../../../src/data/pages/cookie-szabalyzat/index.jsx" /* webpackChunkName: "component---src-data-pages-cookie-szabalyzat-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-jogi-nyilatkozat-index-jsx": () => import("./../../../src/data/pages/jogi-nyilatkozat/index.jsx" /* webpackChunkName: "component---src-data-pages-jogi-nyilatkozat-index-jsx" */),
  "component---src-data-pages-kapcsolat-index-jsx": () => import("./../../../src/data/pages/kapcsolat/index.jsx" /* webpackChunkName: "component---src-data-pages-kapcsolat-index-jsx" */),
  "component---src-data-pages-oldalterkep-index-jsx": () => import("./../../../src/data/pages/oldalterkep/index.jsx" /* webpackChunkName: "component---src-data-pages-oldalterkep-index-jsx" */),
  "component---src-data-pages-szemtorna-index-jsx": () => import("./../../../src/data/pages/szemtorna/index.jsx" /* webpackChunkName: "component---src-data-pages-szemtorna-index-jsx" */),
  "component---src-data-pages-termekeink-index-jsx": () => import("./../../../src/data/pages/termekeink/index.jsx" /* webpackChunkName: "component---src-data-pages-termekeink-index-jsx" */),
  "component---src-data-pages-termekeink-visine-classic-index-jsx": () => import("./../../../src/data/pages/termekeink/visine-classic/index.jsx" /* webpackChunkName: "component---src-data-pages-termekeink-visine-classic-index-jsx" */),
  "component---src-data-pages-voros-szemre-index-jsx": () => import("./../../../src/data/pages/voros-szemre/index.jsx" /* webpackChunkName: "component---src-data-pages-voros-szemre-index-jsx" */),
  "component---src-data-pages-www-birosag-index-jsx": () => import("./../../../src/data/pages/www.birosag/index.jsx" /* webpackChunkName: "component---src-data-pages-www-birosag-index-jsx" */)
}

